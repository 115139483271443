import {
    GetActiveAplications,
    GetApplications,
    SubmitApplication,
    useApplyApplication,
} from '@/core/hooks/application';
import { GetTopics } from '@/core/hooks/topics';
import { ApplicationType } from '@/core/types/application';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
    EditAnswerInfo,
    GetAnswers,
    useCreateAnswer,
} from '@/core/hooks/answers';
import { useAppSelector } from '@/store';
import { GetSuggestedTopics } from '@/core/hooks/suggested-topics';
import IconHome from '@/components/Icon/IconHome';
import IconFile from '@/components/Icon/IconFile';
import IconUsers from '@/components/Icon/IconUsers';
import Button from '@/components/button';
import { useCreateSupervisor } from '@/core/hooks/supervisor';
import MyProfile from '../../view_profile';
import IconSend from '@/components/Icon/IconSend';
import { getMyProfile } from '@/core/hooks/auth';
import SupervisorsInfo from '../../supervisors';
import SuggestedTopics from '../../suggested-topics';
import IconServer from '@/components/Icon/IconServer';
import IconUser from '@/components/Icon/IconUser';
import Modal from '@/components/modal';
import IconCloudDownload from '@/components/Icon/IconCloudDownload';
import toast from 'react-hot-toast';

function ApplicationInfo() {
    const { application_id }: any = useParams();
    const { handleGetApplications, applications } = GetApplications();
    const { handleGetAnswers, answers } = GetAnswers();
    const { handleGetTopics, topics } = GetTopics();
    const { applyApplication, loadingApply } = useApplyApplication();
    const [selectedApplication, setSelectedApplication] =
        useState<ApplicationType | null>(null);

    const location = useLocation();
    const query = location.search;

    useEffect(() => {
        handleGetApplications(query);
        handleGetTopics(query);
        handleGetAnswers(query);
    }, [query]);

    useEffect(() => {
        const selected = applications?.list.find(
            (app: { id: any }) => app.id === application_id
        );

        setSelectedApplication(selected);
    }, [applications, application_id]);

    useEffect(() => {
        const selected = applications?.list.find(
            (app: { id: any }) => app.id === application_id
        );
        setSelectedApplication(selected);
    }, [applications, application_id]);

    const handleApply = () => {
        applyApplication(application_id);
    };

    return (
        <div className=" mx-auto mt-8 p-6  rounded-lg shadow-md">
            <h1 className="text-xl font-bold mb-4 text-primary dark:text-gray-400">
                {selectedApplication?.title}
            </h1>

            <div
                className=" dark:text-gray-400  "
                dangerouslySetInnerHTML={{
                    __html: selectedApplication?.description || '',
                }}
            />
            <div>
                <h2 className="text-2xl font-bold mb-2 text-primary">
                    Instructions
                </h2>
                <div
                    className=" dark:text-gray-400  "
                    dangerouslySetInnerHTML={{
                        __html: selectedApplication?.instruction_note || '',
                    }}
                />
            </div>
        </div>
    );
}

function Topics({ setuserChoice2 }: any) {
    const { application_id }: any = useParams();
    const { handleGetApplications, applications } = GetActiveAplications();
    const { handleGetAnswers, answers } = GetAnswers();
    const { handleGetTopics, loadingTopics, topics } = GetTopics();
    const { applyApplication, loadingApply } = useApplyApplication();
    const { handleCreateAnswer, loadingCreateAnswer } = useCreateAnswer();
    const { loadingEditAnswer, handleEditAnswer } = EditAnswerInfo();

    const [selectedApplication, setSelectedApplication] = useState<any>(null);
    const [selectedTopics, setSelectedTopics] = useState<any[]>([]);

    const [answersMap, setAnswersMap] = useState<{ [key: string]: string }>({});
    const [currentQuestionIndices, setCurrentQuestionIndices] = useState<{
        [key: string]: number;
    }>({});
    const location = useLocation();
    const query = location.search;
    const { userAccount: userInfo }: any = useAppSelector(store => store.user);
    const [userChoice, setUserChoice] = useState('existing');
    const [suggestOwnTopic, setSuggestOwnTopic] = useState(false);

    useEffect(() => {
        setuserChoice2(userChoice);
    }, [userChoice]);

    const updatePageSizeInQuery = (query: string, pageSize: number): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('pageSize', pageSize.toString());
        return '?' + urlParams.toString();
    };
    const updatedQuery = updatePageSizeInQuery(query, 10000000);
    useEffect(() => {
        handleGetApplications(query);
        handleGetTopics(updatedQuery);
        handleGetAnswers(query);
    }, [query]);

    useEffect(() => {
        const selected = applications?.list.find(
            (app: { id: any }) => app.id === application_id
        );
        setSelectedApplication(selected);
    }, [applications, application_id]);

    const handleTopicSelection = (id: any, index: number) => {
        if (selectedTopics[index] !== id) {
            const topic: any = topics?.list.find(
                (topic: any) => topic.id === id
            );
            const updatedTopics = [...selectedTopics];
            updatedTopics[index] = topic;
            setSelectedTopics(updatedTopics);
            setCurrentQuestionIndices(prevIndices => ({
                ...prevIndices,
                [id]: 0,
            }));
            setAnswersMap(prevAnswers => ({
                ...prevAnswers,
                [id]: '',
            }));
        }
    };

    useEffect(() => {
        if (selectedApplication?.topics?.length === 1) {
            handleTopicSelection(selectedApplication.topics[0].id, 0);
        }
    }, [selectedApplication]);

    const handleSubmitAnswers = async (
        topicId: string,
        questionId: string,
        answer: string
    ) => {
        if (!answer || answer.trim() === '') {
            toast.error('Answer is required, type the answer');
            return;
        }
    
        // Optimistically update the answersMap before API call
        setAnswersMap(prevAnswers => ({
            ...prevAnswers,
            [topicId]: answer,
        }));
    
        const submissionData: any = {
            applicationId: application_id,
            topicId,
            questionId,
            answer,
        };
    
        const existingAnswer = answers?.list.find(
            (ans: {
                application: { id: any };
                question: { id: any };
                user: { id: any };
            }) =>
                ans.application.id === application_id &&
                ans.question.id === questionId &&
                ans.user.id === userInfo.id
        );
    
        const UpdatedAnswer: any = { answer };
    
        if (existingAnswer) {
            await handleEditAnswer(UpdatedAnswer, existingAnswer?.id);
        } else {
            await handleCreateAnswer(submissionData);
        }
    
        // Fetch latest answers but keep the old answer until the new ones arrive
        const updatedAnswers: any = await handleGetAnswers(updatedQuery);
    
        console.log('Updated answers after submission:', updatedAnswers?.list);
    
        // Find the newly updated answer
        const latestAnswer = updatedAnswers?.list.find(
            (ans: {
                application: { id: any };
                question: { id: any };
                user: { id: any };
            }) =>
                ans.application.id === application_id &&
                ans.question.id === questionId &&
                ans.user.id === userInfo.id
        );
    
        // Update the state with the new answer once it arrives
        setAnswersMap(prevAnswers => ({
            ...prevAnswers,
            [topicId]: latestAnswer ? latestAnswer.answer : answer, // Keep previous value if no update
        }));
    
        console.log(`Latest answer for question ${questionId}:`, latestAnswer?.answer);
    };
    

    const handleNextQuestion = (topicId: string) => {
        const currentQuestionIndex = currentQuestionIndices[topicId];
        const currentTopic = selectedTopics.find(
            topic => topic?.id === topicId
        );

        if (currentTopic?.questions) {
            const answer = answersMap[topicId];

            if (!answer || answer.trim() === '') {
                toast.error(
                    'Please provide an answer before proceeding to the next question.'
                );
                return;
            }

            // Submit the current answer
            handleSubmitAnswers(
                topicId,
                currentTopic.questions[currentQuestionIndex]?.id,
                answer
            );

            handleGetAnswers(updatedQuery);
            // Move to the next question if available
            if (currentQuestionIndex < currentTopic.questions.length - 1) {
                const nextQuestionIndex = currentQuestionIndex + 1;
                setCurrentQuestionIndices(prevIndices => ({
                    ...prevIndices,
                    [topicId]: nextQuestionIndex,
                }));

                // Retrieve the answer for the next question (if already answered)
                const nextQuestionId =
                    currentTopic.questions[nextQuestionIndex]?.id;

                const existingAnswer = answers?.list.find(
                    (ans: {
                        application: { id: any };
                        question: { id: any };
                        user: { id: any };
                    }) =>
                        ans.application.id === application_id &&
                        ans.question.id === nextQuestionId &&
                        ans.user.id === userInfo.id
                );

                setAnswersMap(prevAnswers => ({
                    ...prevAnswers,
                    [topicId]: existingAnswer ? existingAnswer.answer : '',
                }));
            }
        }
    };

    const handlePreviousQuestion = async (topicId: string) => {
        const currentQuestionIndex = currentQuestionIndices[topicId];

        if (currentQuestionIndex > 0) {
            const prevQuestionIndex = currentQuestionIndex - 1;

            setCurrentQuestionIndices(prevIndices => ({
                ...prevIndices,
                [topicId]: prevQuestionIndex,
            }));

            // Retrieve the latest answer for the previous question
            const prevQuestionId = selectedTopics.find(
                topic => topic?.id === topicId
            )?.questions?.[prevQuestionIndex]?.id;

            const updatedAnswers: any = await handleGetAnswers(query);

            // Log the updated answers in the console
            console.log(
                'Fetched updated answers after navigating back:',
                updatedAnswers?.list
            );

            const existingAnswer = updatedAnswers?.list.find(
                (ans: {
                    application: { id: any };
                    question: { id: any };
                    user: { id: any };
                }) =>
                    ans.application.id === application_id &&
                    ans.question.id === prevQuestionId &&
                    ans.user.id === userInfo.id
            );

            setAnswersMap(prevAnswers => ({
                ...prevAnswers,
                [topicId]: existingAnswer ? existingAnswer.answer : '',
            }));

            // Log the selected answer for the previous question
            console.log(
                `Answer for previous question (ID: ${prevQuestionId}):`,
                existingAnswer?.answer
            );
        }
    };

    const handleApply = () => {
        applyApplication(application_id);
    };

    useEffect(() => {
        selectedTopics.forEach(selectedTopic => {
            if (selectedTopic?.questions) {
                const currentQuestion =
                    selectedTopic.questions[
                        currentQuestionIndices[selectedTopic?.id]
                    ];
                const questionId = currentQuestion?.id;

                const existingAnswer = answers?.list.find(
                    (answer: {
                        application: { id: any };
                        question: { id: any };
                        user: { id: any };
                    }) =>
                        answer.application.id === application_id &&
                        answer.question.id === questionId &&
                        answer.user.id === userInfo?.id
                );

                if (existingAnswer) {
                    setAnswersMap(prevAnswers => ({
                        ...prevAnswers,
                        [selectedTopic?.id]: existingAnswer.answer,
                    }));
                } else {
                    setAnswersMap(prevAnswers => ({
                        ...prevAnswers,
                        [selectedTopic?.id]: '',
                    }));
                }
            }
        });
    }, [
        currentQuestionIndices,
        selectedTopics,
        answers,
        application_id,
        userInfo?.id,
    ]);

    return (
        <div className="container mx-auto p-6">
            {selectedApplication?.allow_suggested_topic && (
                <div className="my-10 mt-3">
                    <div className="my-2">
                        <p className="text-[17px] font-semibold">
                            Select the best option (for this application you
                            will choose either to work on existing topics or
                            suggest your own topic)
                        </p>
                    </div>
                    <label className="inline-flex mx-2 items-center">
                        <input
                            type="radio"
                            className="form-radio"
                            checked={userChoice === 'existing'}
                            onChange={() => {
                                setSelectedTopics([]);
                                setUserChoice('existing');
                            }}
                        />
                        <span className="ml-2 text-[16px]">
                            Choose among existing Topics
                        </span>
                    </label>

                    <label className="inline-flex items-center">
                        <input
                            type="radio"
                            className="form-radio text-primary"
                            checked={userChoice === 'suggested'}
                            onChange={() => {
                                setSelectedTopics([]);
                                setUserChoice('suggested');
                            }}
                        />
                        <span className="ml-2 text-[16px]">
                            Suggest own topic
                        </span>
                    </label>
                </div>
            )}

            {userChoice === 'existing' && (
                <div>
                    {selectedApplication && (
                        <div>
                            {/* <p className="text-lg font-bold">
                                Allowed number of topics:{' '}
                                <span className="font-bold ">
                                    {selectedApplication?.maximum_topic_number}
                                </span>
                            </p> */}
                        </div>
                    )}
                    <p className="text-[16px] text-gray-700 mt-2">
                        Before you begin, please read the instructions below
                        carefully:
                    </p>
                    <ul className="list-disc list-inside text-[16px] text-gray-700 mt-2">
                        <li>
                            Carefully review the instructions for the
                            topic and the overall application guidelines
                        </li>
                        <li>
                            <strong>
                                It is crucial to answer and save answer to each
                                question.
                            </strong>
                        </li>
                        <li>
                            You can go to the application submission to check if
                            all questions have been answered correctly
                        </li>
                        {/* <li>
                            Before submitting your application, please ensure
                            that you have workedon the correct number of topics
                            required on application and provided answers to
                            every question within each topic.
                        </li> */}

                        {/* <li>
                            Ensure the topic you choose is the most suitable for
                            you.{' '}
                            <strong>
                                Once you start answering, you cannot switch
                                topics.
                            </strong>
                        </li> */}
                        {/* <li className="font-bold">
                            For this application, you will work on{' '}
                            {selectedApplication?.maximum_topic_number}{' '}
                            topic(s).
                        </li> */}
                    </ul>
                    <div
                        className={`grid ${
                            selectedApplication?.maximum_topic_number === 1
                                ? 'grid-cols-1'
                                : 'grid-cols-2'
                        } gap-4 w-full`}
                    >
                        {[
                            ...Array(selectedApplication?.maximum_topic_number),
                        ].map((_, index) => (
                            <div key={index} className="p-4">
                                <div className="mt-8 dark:text-gray-400">
                                    <div className="grid grid-cols-1 w-[70%]">
                                        {userChoice === 'existing' &&
                                        selectedApplication ? (
                                            <>
                                                <h2 className="text-xl font-bold text-primary my-4">
                                                    {selectedTopics?.length > 1
                                                        ? `Topic ${
                                                              index + 1
                                                          } of ${
                                                              selectedTopics?.length
                                                          }`
                                                        : ``}
                                                </h2>
                                                {selectedApplication.maximum_topic_number >
                                                1 ? (
                                                    <>
                                                        {' '}
                                                        <label
                                                            htmlFor=""
                                                            className="font-bold text-lg"
                                                        >
                                                            {index + 1}.Select
                                                            the topic
                                                        </label>
                                                        <select
                                                            name="topicSelect"
                                                            id={`topicSelect_${index}`}
                                                            className="form-select mt-2 p-2 border 
                                                     rounded-md"
                                                            onChange={e =>
                                                                handleTopicSelection(
                                                                    e.target
                                                                        .value,
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            <option value="">
                                                                Select topic
                                                            </option>
                                                            {selectedApplication?.topics?.map(
                                                                (
                                                                    topic: any
                                                                ) => (
                                                                    <option
                                                                        key={
                                                                            topic?.id
                                                                        }
                                                                        value={
                                                                            topic?.id
                                                                        }
                                                                    >
                                                                        {
                                                                            topic.name
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        ) : (
                                            loadingTopics && (
                                                <p className="text-lg font-bold">
                                                    Loading topics...
                                                </p>
                                            )
                                        )}

                                        {selectedApplication?.topics?.length ===
                                            0 && (
                                            <p className="text-lg font-bold">
                                                No topics found for this
                                                Application
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            {userChoice === 'suggested' && <SuggestedTopics />}
            <div
    className={`grid ${
        selectedApplication?.maximum_topic_number === 1
            ? 'grid-cols-1'
            : 'grid-cols-2'
    } gap-4 w-full`}
>
    {selectedTopics &&
        selectedTopics.map((selectedTopic, index) => {
            // Ensure questions exist and default to an empty array if undefined
            let questions = selectedTopic?.questions ? [...selectedTopic.questions] : [];

            // If no questions exist, return early to avoid map errors
            if (!questions.length) {
                return (
                    <div key={selectedTopic?.id} className="p-6 panel rounded shadow-md bg-white">
                        <p className="text-xl font-bold">No questions available for this topic.</p>
                    </div>
                );
            }

            const currentQuestionIndex = currentQuestionIndices[selectedTopic?.id] || 0;
            const currentQuestion = questions[currentQuestionIndex];

            return (
                <div key={selectedTopic?.id} className="p-6 panel rounded shadow-md bg-white">
                    <div>
                        <p className="text-xl mb-2 my-4 font-bold">{selectedTopic?.name}</p>

                        <div>
                            <h2 className="text-lg my-4 font-semibold">Topic instruction</h2>
                            <div
                                className="leading-relaxed mt-2"
                                dangerouslySetInnerHTML={{ __html: selectedTopic?.instruction_note || "" }}
                            />
                        </div>
                    </div>

                    {selectedApplication && questions.length > 0 && (
                        <div className="mt-4">
                            <h3 className="text-xl font-bold mb-2 text-primary">Questions</h3>
                            {currentQuestion && (
                                <div className="mb-4">
                                    <div className="mb-3">
                                        <h4 className="font-semibold text-lg leading-2 text-gray-800">
                                            {currentQuestionIndex + 1}. {currentQuestion.title}
                                        </h4>
                                        <p className="text-gray-600 mt-1">{currentQuestion.description}</p>
                                    </div>

                                    <label htmlFor="answer">Your answer</label>

                                    {/* Check if the question is "Center of Preference" */}
                                    {currentQuestion.title === "Center of Preference" ? (
                                        <select
                                            className="form-select mt-2 p-2 border rounded-md w-full"
                                            value={answersMap[selectedTopic?.id] || ""}
                                            onChange={(e) => {
                                                setAnswersMap((prevAnswers) => ({
                                                    ...prevAnswers,
                                                    [selectedTopic?.id]: e.target.value,
                                                }));
                                            }}
                                        >
                                            <option value="">Select Center</option>
                                            <option value="AIMS Rwanda">AIMS Rwanda</option>
                                            <option value="AIMS Cameroon">AIMS Cameroon</option>
                                        </select>
                                    ) : (
                                        <textarea
                                            className="form-textarea mt-2 p-2 border rounded-md w-full h-32"
                                            placeholder="Type your answer..."
                                            value={answersMap[selectedTopic?.id] || ""}
                                            onChange={(e) => {
                                                setAnswersMap((prevAnswers) => ({
                                                    ...prevAnswers,
                                                    [selectedTopic?.id]: e.target.value,
                                                }));
                                            }}
                                        />
                                    )}

                                    <div className="mt-4 flex justify-between">
                                        <button
                                            className="btn btn-secondary btn-sm"
                                            onClick={() => handlePreviousQuestion(selectedTopic?.id)}
                                            disabled={currentQuestionIndex === 0}
                                        >
                                            Previous Question
                                        </button>
                                        {currentQuestionIndex < questions.length - 1 ? (
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => handleNextQuestion(selectedTopic?.id)}
                                            >
                                                Save and Next
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-success btn-sm"
                                                onClick={() =>
                                                    handleSubmitAnswers(
                                                        selectedTopic?.id,
                                                        currentQuestion?.id,
                                                        answersMap[selectedTopic?.id]
                                                    )
                                                }
                                            >
                                                Submit
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            );
        })}
</div>
        </div>
    );
}

function CreateSupervisor({ refresh }: any) {
    const [modal, setModal] = React.useState(false);
    const { loadingCreateSupervisor, handleCreateSupervisor } =
        useCreateSupervisor();
    const {
        loadingSuggestedTopics,
        handleGetSuggestedTopics,
        suggestedTopics,
    } = GetSuggestedTopics();

    const query = location.search;

    useEffect(() => {
        handleGetSuggestedTopics(query);
    }, [query]);

    return (
        <div>
            <SupervisorsInfo />
        </div>
    );
}

export default function Apply() {
    const [userChoice, setUserChoice] = useState('suggested');
    const [activeTab2, setActiveTab2] = useState<any>(1);

    const { applyApplication, loadingApply } = useApplyApplication();
    const { application_id }: any = useParams();
    const { loadingPrifile, handleGetProfile, profile } = getMyProfile();
    useEffect(() => {
        handleGetProfile();
    }, []);

    const application = profile?.applications?.find(
        (application: { id: any }) => application?.id === application_id
    );

    const { loadingSubmit, submitApplication } = SubmitApplication();

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const handleApply = (application_id: any) => {
        setConfirmationOpen(true);
    };

    const tabs = [
        {
            icon: IconHome,
            title: 'Application Information',
            selected: true,
        },
        {
            icon: IconFile,
            title: 'Topics',
            selected: true,
        },

        {
            icon: IconCloudDownload,
            title: 'Upload Files',
            selected: true,
        },
    ];
    if (userChoice === 'suggested') {
        tabs.push({
            icon: IconUsers,
            title: 'Supervisors',
            selected: true,
        });
    }

    return (
        <div className="text-gray-700 dark:text-gray-400">
            <div className="panel mt-5" id="icon">
                <div className="inline-block w-full">
                    <div className="relative z-[1]">
                        <div></div>
                        <ul className="mb-5 grid grid-cols-4">
                            <li>
                                <div
                                    className={`${
                                        activeTab2 === 1
                                            ? '!bg-primary text-white'
                                            : ''
                                    }
                flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                                    onClick={() => setActiveTab2(1)}
                              
                              >
                                    <IconHome />
                                </div>
                                <span className="text-center block mt-2">
                                    Application Details
                                </span>
                            </li>
                            <li>
                                <div
                                    className={`${
                                        activeTab2 === 2
                                            ? '!bg-primary text-white'
                                            : ''
                                    }
                flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                                    onClick={() => setActiveTab2(2)}
                                >
                                    <IconServer />
                                </div>
                                <span className="text-center block mt-2">
                                    Application
                                </span>
                            </li>
                            {application?.allow_suggested_topic && (
                                <li>
                                    <div
                                        className={`${
                                            activeTab2 === 3
                                                ? '!bg-primary text-white'
                                                : ''
                                        }
                 flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                                        onClick={() => setActiveTab2(3)}
                                    >
                                        <IconUser />
                                    </div>
                                    <span
                                        className={`text-center block mt-2 ${
                                            activeTab2 === 3
                                                ? 'text-primary '
                                                : ''
                                        }`}
                                    >
                                        Supervisor
                                    </span>
                                </li>
                            )}
                            <li>
                                <div
                                    className={`${
                                        activeTab2 === 4
                                            ? '!bg-primary text-white'
                                            : ''
                                    }
                flex items-center justify-center rounded-full bg-[#f3f2ee] p-2.5 dark:bg-[#1b2e4b]`}
                                    onClick={() => setActiveTab2(4)}
                                >
                                    <IconSend />
                                </div>
                                <span
                                    className={`text-center block mt-2 ${
                                        activeTab2 === 4 ? 'text-primary ' : ''
                                    }`}
                                >
                                    Application Submission
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p className="mb-5">
                            {activeTab2 === 1 && <ApplicationInfo />}
                        </p>

                        <p className="mb-5">
                            {activeTab2 === 2 && (
                                <Topics setuserChoice2={setUserChoice} />
                            )}
                        </p>

                        {application?.allow_suggested_topic && (
                            <>
                                <p className="mb-5">
                                    {activeTab2 === 3 && <CreateSupervisor />}
                                </p>
                            </>
                        )}
                        <p className="mb-5">
                            {activeTab2 === 4 && <ApplicationSubmission />}
                        </p>
                    </div>

                    <div className="flex justify-between">
                        {activeTab2 === 4 ? (
                            <>
                                <button
                                    type="button"
                                    className={`btn btn-primary ${
                                        activeTab2 === 1 ? 'hidden' : ''
                                    }`}
                                    onClick={() =>
                                        setActiveTab2(activeTab2 === 3 ? 2 : 1)
                                    }
                                >
                                    Back
                                </button>
                                <button
                                    className="btn btn-primary "
                                    onClick={handleApply}
                                >
                                    Submit
                                </button>
                            </>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    className={`btn btn-primary ${
                                        activeTab2 === 1 ? 'hidden' : ''
                                    }`}
                                    onClick={() =>
                                        setActiveTab2(activeTab2 === 3 ? 2 : 1)
                                    }
                                >
                                    Back
                                </button>

                                <button
                                    className="btn btn-primary ltr:ml-auto rtl:mr-auto"
                                    onClick={() =>
                                        setActiveTab2(
                                            activeTab2 === 1
                                                ? 2
                                                : activeTab2 === 2 &&
                                                    !application?.allow_suggested_topic
                                                  ? 4
                                                  : activeTab2 === 2 &&
                                                      application?.allow_suggested_topic
                                                    ? 3
                                                    : activeTab2 === 3
                                                      ? 4
                                                      : activeTab2
                                        )
                                    }
                                >
                                    Next
                                </button>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <ConfirmationModal
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                onConfirm={() => {
                    submitApplication(application_id);
                    setConfirmationOpen(false);
                }}
            />
        </div>
    );
}

function ApplicationSubmission() {
    const { applyApplication, loadingApply } = useApplyApplication();
    const { application_id }: any = useParams();
    const { loadingPrifile, handleGetProfile, profile } = getMyProfile();

    useEffect(() => {
        handleGetProfile();
    }, []);

    const application = profile?.applications?.find(
        (application: { id: any }) => application.id === application_id
    );

    const { loadingSubmit, submitApplication } = SubmitApplication();

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const handleApply = (application_id: any) => {
        setConfirmationOpen(true);
    };

    return (
        <>
            <MyProfile />
            <div className="border border-primary mt-3">
                <div className="uppercase text-lg font-bold p-2 bg-primary flex flex-row justify-center text-white">
                    Application Details
                </div>
                <div className="p-3">
                    <h1 className="text-lg font-bold mb-4 text-primary">
                        {application?.title}
                    </h1>

                    <div
                        className="dark:text-gray-400"
                        dangerouslySetInnerHTML={{
                            __html: application?.description || '',
                        }}
                    />

                    {application?.topics &&
                        application?.topics.map((topic: any) => {
                            // Sort questions to ensure "Center of Preference" comes first
                            let sortedQuestions = topic?.questions
                                ? [...topic.questions]
                                : [];

                            sortedQuestions.sort((a, b) =>
                                a.title === 'Center of Preference'
                                    ? -1
                                    : b.title === 'Center of Preference'
                                    ? 1
                                    : 0
                            );

                            return (
                                <div key={topic.id}>
                                    <p className="text-xl font-bold">Topic</p>
                                    <div>
                                        <h3 className="text-lg font-bold mb-2 mx-2 text-gray-800 dark:text-gray-400">
                                            Name:{' '}
                                            <span className="font-normal">
                                                {topic?.name}
                                            </span>
                                        </h3>
                                        <p className="mx-2 font-bold text-lg text-gray-800 dark:text-gray-400">
                                            Description:{' '}
                                            <span className="font-normal">
                                                {topic?.description}
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <p className="my-2 text-lg font-bold">
                                            Questions
                                        </p>
                                        <div className="flex flex-col space-y-4">
                                            {sortedQuestions.map(
                                                (question: any, index: number) => (
                                                    <div
                                                        key={question.id}
                                                        className="flex flex-col items-start space-y-2"
                                                    >
                                                        <div className="flex flex-row">
                                                            <div className="bg-gray-200 dark:bg-gray-800 p-3 rounded-lg max-w-md">
                                                                <div>
                                                                    <p className="capitalize">
                                                                        <span className="text-lg font-bold">
                                                                            {index + 1}.
                                                                        </span>{' '}
                                                                        {question.title}
                                                                    </p>
                                                                    <p className="ml-4">
                                                                        {question.description}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="font-bold">
                                                                <p className="">Answer</p>
                                                            </div>

                                                            {question?.answers?.map(
                                                                (answer: any) => (
                                                                    <div
                                                                        key={answer.id}
                                                                        className="flex mt-1 flex-col items-end"
                                                                    >
                                                                        <div className="bg-gray-100 dark:bg-gray-800 p-3 rounded-lg">
                                                                            <p>{answer.answer}</p>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    <div className="mt-4">
                        {application?.suggestedTopics.length > 0 && (
                            <p className="text-lg font-bold">Suggested Topics</p>
                        )}
                        {application?.suggestedTopics &&
                            application?.suggestedTopics.map(
                                (topic: any, index: any) => (
                                    <div key={index}>
                                        <div className="mt-2">
                                            <h3 className="text-lg font-bold mx-2 text-gray-800 dark:text-gray-400">
                                                {index + 1}. Name:{' '}
                                                <span>{topic?.title}</span>
                                            </h3>
                                            <p className="mx-2 font-bold text-md text-gray-600 dark:text-gray-400">
                                                Description:{' '}
                                                <span className="font-normal">
                                                    {topic?.description}
                                                </span>
                                            </p>
                                        </div>
                                        <div>
                                            <div className="flex flex-col px-4">
                                                <div className="text-lg font-bold">
                                                    <p>Skills Needed</p>
                                                </div>

                                                <div className="flex flex-wrap mt-2">
                                                    {topic?.skills_needed?.map(
                                                        (skill: any) => (
                                                            <div
                                                                key={skill?.name}
                                                                className="bg-primary text-white py-1 px-2 rounded-full mr-2 mb-2"
                                                            >
                                                                # {skill?.name}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                    </div>
                </div>
            </div>
            <ConfirmationModal
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                onConfirm={() => {
                    submitApplication(application_id);
                    setConfirmationOpen(false);
                }}
            />
        </>
    );
}

const ConfirmationModal = ({ open, setOpen, onConfirm }: any) => {
    return (
        <Modal show={open} setShow={setOpen} title="Confirm Submission">
            <p>
                By submitting this application, I certify that all information
                provided is accurate to the best of my knowledge. I also confirm
                that I have submitted ONLY ONE application for this call
            </p>
            <div className="flex gap-3  justify-end">
                <Button onClick={() => setOpen(false)} background="bg-gray-500">
                    Cancel
                </Button>
                <Button onClick={onConfirm}>Submit</Button>
            </div>
        </Modal>
    );
};
