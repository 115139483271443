import React, { Fragment, useEffect, useState } from 'react';
import { SelectField } from '@/components/input';
import {
    GetfilesTypes,
    Getfiless,
    useDeletefiles,
    useUploadFile,
} from '@/core/hooks/file';
import axios from 'axios';
import Button from '@/components/button';
import { CircularProgress } from '@mui/material';
import { Dialog, Transition } from '@headlessui/react';
import IconPlus from '@/components/Icon/IconPlus';
import IconEye from '@/components/Icon/IconEye';
import IconTrash from '@/components/Icon/IconTrash';

function AddFile({ setRefresh }: any) {
    const [modal, setModal] = useState(false);
    const [images, setImages] = useState<any>();
    const { loadingfiles, files, handleGetfiles } = GetfilesTypes();
    const { loadingCreate, handleUploadfiles } = useUploadFile();
    const [selectedFileType, setSelectedFileType] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string>('');
    const [loadingCloudinary, setLoadingCloudinary] = useState<boolean>(false);
    const [loadingBackend, setLoadingBackend] = useState<boolean>(false);

    const querry = location.search;
    useEffect(() => {
        handleGetfiles(querry);
    }, []);

    async function handleFileUpload() {
        if (!file) {
            setError('Please select a file.');
            return;
        }
        if (!selectedFileType) {
            setError('Please select a file type.');
            return;
        }
    
        setError('');
        setLoadingCloudinary(true);
    
        const formData = new FormData();
        formData.append('file', file);
    
        for (let [key, value] of formData.entries()) {
           
        }
    
        const encodedCredentials = btoa('admin:password123');
    
        try {
            const response = await axios.post(
                'https://files.aimsric.org/upload',
                formData,
                {
                    headers: {
                        Authorization: `Basic ${encodedCredentials}`,
                       
                    },
                    params:{
                        dir: 'mathEpi/docs'
                    }
                }

            );
    

            
            const file_link = response.data.file_url;
            const fileTypeId = selectedFileType;
            const data = { fileTypeId, file_link };
    
            setLoadingCloudinary(false);
            setLoadingBackend(true);
    
            await handleUploadfiles(data);
            setLoadingBackend(false);
            setRefresh(true);
            setTimeout(() => {
                setRefresh(false);
            }, 1000);
            setModal(false);
            handleGetfiles(querry);
        } catch (error) {
            console.error('Error uploading file:', error);
            setLoadingCloudinary(false);
        }
    }
    
    

    return (
        <div className="">
            <div className="flex items-center justify-end">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="btn btn-primary "
                >
                    <IconPlus />
                    Add file
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="file-upload-container p-4">
                                        <div>
                                            <p className="text-lg font-bold ">
                                                {' '}
                                                Upload files
                                            </p>
                                        </div>
                                        <div className="file-upload-fields  my-2 p-4 space-y-3">
                                            <SelectField
                                                placeholder="Select File "
                                                isLoading={
                                                    loadingCreate ||
                                                    loadingfiles
                                                }
                                                label=" Select File "
                                                options={(files?.list || [])
                                                    .filter(
                                                        (file: {
                                                            name: string;
                                                        }) =>
                                                            file.name !==
                                                            'Recommendation Letter'
                                                    )
                                                    .map(
                                                        (app: {
                                                            id: any;
                                                            name: any;
                                                        }) => ({
                                                            value: app?.id,
                                                            text: app?.name,
                                                        })
                                                    )}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLSelectElement>
                                                ) => {
                                                    setSelectedFileType(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            <label htmlFor="">
                                                Please choose file{' '}
                                                <span className="font-semibold">
                                                    PDF
                                                </span>
                                            </label>
                                            <input
                                                type="file"
                                                className="form-input"
                                                accept=".pdf"
                                                placeholder="Choose the PDF file"
                                                onChange={(e: any) =>
                                                    setFile(e.target.files[0])
                                                }
                                            />
                                        </div>
                                        {error && (
                                            <p className="error-message">
                                                {error}
                                            </p>
                                        )}
                                        <Button
                                            className="upload-button btn btn-primary  btn-lg tm-4 "
                                            onClick={handleFileUpload}
                                        >
                                            {loadingCloudinary ||
                                            loadingBackend ? (
                                                <CircularProgress
                                                    style={{ color: 'white ' }}
                                                />
                                            ) : (
                                                'Upload'
                                            )}
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}

export default function FileUpload() {
    const querry = location.search;
    const { loadingfiless, filess, handleGetfiless } = Getfiless();
    const { loadingDelete, handleDeletefile } = useDeletefiles();
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        handleGetfiless(querry);
    }, [refresh]);

    return (
        <div className="panel mx-w-lg">
             
            <AddFile setRefresh={setRefresh} />
            {filess && filess.list.length > 0 ? (
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                No
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                File Type
                            </th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {filess.list.map((file: any, index: number) => (
                            <tr key={file.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900">
                                        {index + 1}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900">
                                        {file.fileType.name}
                                    </div>
                                </td>
                                <td className="px-6 py-4  flex flex-row  text-sm font-medium">
                                    <a
                                        href={file.file_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-500 hover:underline mr-4"
                                    >
                                        <IconEye />
                                    </a>
                                    <button
                                        onClick={() => {
                                            handleDeletefile(file.id);
                                            setRefresh(true);
                                        }}
                                        className="text-red-500 hover:text-red-700"
                                    >
                                        <IconTrash />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No files found.</p>
            )}
        </div>
    );
}
