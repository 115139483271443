import { SocioEconomicPage } from '@/app/dashboard/view_profile/soci';
import Button from '@/components/button';
import Modal from '@/components/modal';
import {
    GetSubmittedUserDetails,
    getUserSubmittedDetails,
} from '@/core/hooks/application';
import {
    EvaluationStepThree,
    useGetEvaluations,
} from '@/core/hooks/evaluators';
import { Getfiless } from '@/core/hooks/file';
import { AcademicInfoType } from '@/core/types/academic';
import { AddressType } from '@/core/types/address';
import { ExperienceType } from '@/core/types/experience';
import { LanguageType } from '@/core/types/languages';
import { PersonalType } from '@/core/types/personal_info';
import { ReferenceType } from '@/core/types/reference';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { CircularProgress, capitalize } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function ReviewUserStep3() {
    const { userId }: any = useParams();
    const { rowId }: any = useParams();
    const { applicationId }: any = useParams();
    const [comment, setComment] = useState<string>('');
    const query = location.search;
    const { loadingApplications, handleGetApplications, applications } =
        getUserSubmittedDetails();

    const navigate = useNavigate();

    const { loadingEvaluaete, handleEvaluete } = EvaluationStepThree();
    const [confirmationOpen, setConfirmationOpen] = useState(false);

    const { loadingUserdata, handleGetUserInfo, usersInfo } =
        GetSubmittedUserDetails();

    const { loadingEvaluations, handleGetEvaluations, evaluations } =
        useGetEvaluations();

    const [status, setMarks] = useState<string | any>('');

    const { loadingfiless, filess, handleGetfiless } = Getfiless();

    const updatePageSizeInQuery = (query: string): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('userId', userId.toString());
        return '?' + urlParams.toString();
    };

    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query);

        handleGetApplications(userId);
        handleGetEvaluations(query);
        handleGetUserInfo(userId);

        handleGetfiless(updatedQuery);
    }, []);

    const filteredUser = evaluations?.list
        ? evaluations.list.find((item: any) => {
              return item.submittedApplication.user.id === userId;
          })
        : [];

    if (loadingApplications) {
        return (
            <div className="flex flex-row justify-center">
                <CircularProgress style={{ color: 'black' }} />
            </div>
        );
    }

    const application = applications?.applications?.find(
        (app: any) => app.id === applicationId
    );

    const handleMarkApplication = () => {
        setConfirmationOpen(true);
    };

    const filteredFiles = filess?.list?.filter(
        (file: any) => file.user.id === userId
    );

    return (
        <>
            <div className="panel w-full   p-5 ">
                <div className="pt-5 font-semibold flex flex-col gap-3">
                                  <div className=" border border-primary  ">
                                      <div className="bg-primary flex flex-row justify-center p-2 text-lg  text-white ">
                                          PROFILE INFORMATION
                                      </div>
                                      <div className="w-full py-1 flex justify-center items-center   bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold">
                                          IDENTIFICATION
                                      </div>
                                      {usersInfo?.personalInformation?.map(
                                          (personalInfo: PersonalType) => (
                                              <div className="py-4 flex justify-start items-start gap-3 flex-wrap">
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          First Name:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.firstName}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          Middle Name:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.middleName || 'None'}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          Last Name:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.lastName || '-'}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          Phone Number:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.phoneNumber}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          Email:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.email}
                                                      </p>
                                                  </div>{' '}
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          Gender:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.gender}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          Date of Birth:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {formatDateToLongForm(
                                                              personalInfo?.date_of_birth
                                                          )}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          Martial Status:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.maritalStatus}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          Nationality:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.Nationality}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          Citizenship:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.citizenship || '-'}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          FaceBook:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.facebook || '-'}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          Twitter:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.twitter}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          LinkedIn:
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.linkedIn}
                                                      </p>
                                                  </div>
                                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                      <p className="font-bold dark:text-gray-300">
                                                          Has Passport :
                                                      </p>
                                                      <p className="font-semibold text-gray-500">
                                                          {personalInfo?.hasPassport
                                                              ? 'Yes'
                                                              : 'No'}
                                                      </p>
                                                  </div>
                                                  {personalInfo?.hasPassport && (
                                                      <>
                                                          <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                              <p className="font-bold dark:text-gray-300">
                                                                  Passport Number:
                                                              </p>
                                                              <p className="font-semibold text-gray-500">
                                                                  {personalInfo?.passportNumber ||
                                                                      '-'}
                                                              </p>
                                                          </div>
                                                          <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                              <p className="font-bold dark:text-gray-300">
                                                                  Passport Expiry Date:
                                                              </p>
                                                              <p className="font-semibold text-gray-500">
                                                                  {formatDateToLongForm(
                                                                      personalInfo?.passport_expired_data
                                                                  ) || '-'}
                                                              </p>
                                                          </div>
                                                      </>
                                                  )}
                                                   <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                      <p className="font-bold dark:text-gray-300">
                                          Is refugee:
                                      </p>
                                      <p className="font-semibold text-gray-500">
                                          {personalInfo?.refugee_status ? 'Yes' : 'No'}
                                      </p>
                                  </div>
                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                      <p className="font-bold dark:text-gray-300">
                                          Refugee Support Details:
                                      </p>
                                      <p className="font-semibold text-gray-500">
                                          {personalInfo?.refugee_support_details  || '-'}
                                      </p>
                                  </div>
              
                                  <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                      <p className="font-bold dark:text-gray-300">
                                          {' '}
                                          Have disablility:
                                      </p>
                                      <p className="font-semibold text-gray-500">
                                          {personalInfo?.disability_status ? 'Yes' : 'No'}
                                      </p>
                                  </div>
                                  {personalInfo?.disability_status && (
                                      <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                          <p className="font-bold dark:text-gray-300">
                                              Disability Accommodations:
                                          </p>
                                          <p className="font-semibold text-gray-500">
                                              {personalInfo?.disability_accommodations || '-'}
                                          </p>
                                      </div>)}
                                              </div>
                                          )
                                      )}
              
                                      <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold">
                                          ADDRESS INFORMATION
                                      </div>
              
                                      <div className="flex justify-start items-start gap-3 flex-wrap">
                                          <div className=" paneltable-responsive  w-full m-5">
                                              <table className=" border  rounded  ">
                                                  <thead className=" ">
                                                      <tr>
                                                          <th className="font-bold">
                                                              Address Type
                                                          </th>
                                                          <th className="font-bold">City</th>
                                                          <th className="font-bold">
                                                              street
                                                          </th>
                                                          <th className="font-bold">
                                                              Date Added
                                                          </th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      {usersInfo?.address?.map(
                                                          (data: AddressType) => {
                                                              return (
                                                                  <tr key={data.id}>
                                                                      <td>
                                                                          <div className="whitespace-nowrap">
                                                                              {capitalize(
                                                                                  data.type
                                                                              )}
                                                                          </div>
                                                                      </td>
                                                                      <td>
                                                                          {capitalize(
                                                                              data.city
                                                                          )}
                                                                      </td>
                                                                      <td>
                                                                          {data.street || '-'}
                                                                      </td>
                                                                      <td>
                                                                          {formatDateToLongForm(
                                                                              data.created_at
                                                                          ) || '-'}{' '}
                                                                      </td>
                                                                  </tr>
                                                              );
                                                          }
                                                      )}
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                      <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                                          Academic Information
                                      </div>
              
                                      <div className="flex justify-start items-start gap-3 flex-wrap">
                                          <div className="table-responsive  w-full m-5">
                                              <table className="border rounded   ">
                                                  <thead>
                                                      <tr>
                                                          <th className="font-bold">
                                                              Institution
                                                          </th>
                                                          <th className="font-bold">
                                                              Country
                                                          </th>
              
                                                          <th className="font-bold whitespace-nowrap">
                                                              Graduation Date
                                                          </th>
                                                          <th className="font-bold whitespace-nowrap ">
                                                              Level of Study
                                                          </th>
                                                          <th className="font-bold">
                                                              Degree
                                                          </th>
                                                          <th className="font-bold">Major</th>
              
                                                          <th className="font-bold whitespace-nowrap">
                                                              Language of Instructions
                                                          </th>
                                                          <th className="font-bold whitespace-nowrap">
                                                              Highest Qualification
                                                          </th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      {usersInfo?.academicHistories?.map(
                                                          (data: AcademicInfoType) => {
                                                              return (
                                                                  <tr key={data.id}>
                                                                      <td>
                                                                          {data.institution}
                                                                      </td>
                                                                      <td>{data.country}</td>
              
                                                                      <td>
                                                                          {formatDateToLongForm(
                                                                              data.graduationDate
                                                                          ) || '-'}
                                                                      </td>
                                                                      <td>
                                                                          {data.levelOfStudy}
                                                                      </td>
                                                                      <td>{data.degree}</td>
                                                                      <td>{data.major}</td>
              
                                                                      <td>
                                                                          {data.language_of_instructions ||
                                                                              '-'}
                                                                      </td>
                                                                      <td>
                                                                          {data.highest_qualification
                                                                              ? 'Yes'
                                                                              : 'No'}
                                                                      </td>
                                                                  </tr>
                                                              );
                                                          }
                                                      )}
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                      <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                                          Experience Information
                                      </div>
              
                                      <div className="flex justify-start items-start gap-3 flex-wrap">
                                          <div className="table-responsive  w-full m-5">
                                              <table className="border rounded">
                                                  <thead>
                                                      <tr>
                                                          <th className="font-bold whitespace-nowrap">
                                                              Employer Name
                                                          </th>
                                                          <th className="font-bold">
                                                              Country
                                                          </th>
              
                                                          <th className="font-bold whitespace-nowrap">
                                                              Start Date
                                                          </th>
                                                          <th className="font-bold whitespace-nowrap">
                                                              End Date
                                                          </th>
                                                          <th className="font-bold">
                                                              Position
                                                          </th>
                                                          <th className="font-bold whitespace-nowrap">
                                                              Roles and Responsibilities
                                                          </th>
                                                          <th className="font-bold">Type</th>
                                                          <th className="font-bold whitespace-nowrap">
                                                              Employer Sector
                                                          </th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      {usersInfo?.experience?.map(
                                                          (data: ExperienceType) => {
                                                              return (
                                                                  <tr key={data.id}>
                                                                      <td>
                                                                          {data.employer_name}
                                                                      </td>
                                                                      <td>{data.country}</td>
              
                                                                      <td>
                                                                          {formatDateToLongForm(
                                                                              data.start_date
                                                                          ) || '-'}
                                                                      </td>
                                                                      <td>
                                                                          {formatDateToLongForm(
                                                                              data.end_date
                                                                          ) || '-'}
                                                                      </td>
                                                                      <td>{data.position}</td>
                                                                      <td>
                                                                          {data.roles_and_responsibilities ||
                                                                              '-'}
                                                                      </td>
                                                                      <td>{data.type}</td>
                                                                      <td>
                                                                          {data.employer_sector ||
                                                                              '-'}
                                                                      </td>
                                                                  </tr>
                                                              );
                                                          }
                                                      )}
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                      <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                                          Languages
                                      </div>
              
                                      <div className="flex justify-start items-start gap-3 flex-wrap">
                                          <div className="table-responsive  w-full m-5">
                                              <table className="border rounded">
                                                  <thead>
                                                      <tr>
                                                          <th className="font-bold">
                                                              Language
                                                          </th>
                                                          <th className="font-bold">
                                                              Verbal Level
                                                          </th>
                                                          <th className="font-bold">
                                                              Written Level
                                                          </th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      {usersInfo?.language?.map(
                                                          (data: LanguageType) => {
                                                              return (
                                                                  <tr key={data.id}>
                                                                      <td>{data.name}</td>
                                                                      <td>
                                                                          {data.verbal_level}
                                                                      </td>
                                                                      <td>
                                                                          {data.written_level}
                                                                      </td>
                                                                  </tr>
                                                              );
                                                          }
                                                      )}
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                      <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                                  Social Economic Information
                              </div>
              
                              <div className="flex justify-start items-start gap-3 flex-wrap">
                                 <SocioEconomicPage userId={userId}/>
                              </div>
                                      <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                                          reference information
                                      </div>
              
                                      <div className="flex justify-start items-start gap-3 flex-wrap">
                                          <div className="table-responsive  w-full m-5">
                                              <table className="border rounded">
                                                  <thead>
                                                      <tr>
                                                          <th className="font-bold whitespace-nowrap">
                                                              First Name
                                                          </th>
                                                          <th className="font-bold whitespace-nowrap">
                                                              Last Name
                                                          </th>
                                                          <th className="font-bold">
                                                              Company
                                                          </th>
                                                          <th className="font-bold">
                                                              Position
                                                          </th>
                                                          <th className="font-bold whitespace-nowrap">
                                                              Relationship
                                                          </th>
                                                          <th className="font-bold">Email</th>
                                                          <th className="font-bold whitespace-nowrap ">
                                                              Phone Number
                                                          </th>
                                                          <th className="font-bold">
                                                              Country
                                                          </th>
                                                      </tr>
                                                  </thead>
                                                  <tbody>
                                                      {usersInfo?.reference?.map(
                                                          (data: ReferenceType) => {
                                                              return (
                                                                  <tr key={data.id}>
                                                                      <td>
                                                                          {data.firstName}
                                                                      </td>
                                                                      <td>{data.lastName}</td>
                                                                      <td>{data.company}</td>
                                                                      <td>{data.position}</td>
                                                                      <td>
                                                                          {data.relationship}
                                                                      </td>
                                                                      <td>{data.email}</td>
                                                                      <td>
                                                                          {data.phoneNumber}
                                                                      </td>
                                                                      <td>{data.country}</td>
                                                                  </tr>
                                                              );
                                                          }
                                                      )}
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                             
                                      <div className="">
                                          <h2 className="w-full py-2 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                                              Attached Files
                                          </h2>
                                          <table className="min-w-full divide-y divide-gray-200">
                                              <thead className="bg-gray-50">
                                                  <tr>
                                                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                          #
                                                      </th>
                                                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                          File Type
                                                      </th>
                                                  </tr>
                                              </thead>
                                              <tbody className="bg-white divide-y divide-gray-200">
                                                  {filess &&
                                                      filteredFiles.map(
                                                          (file: any, index: number) => (
                                                              <tr
                                                                  key={file.id}
                                                                  className="hover:bg-gray-100"
                                                              >
                                                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                                      {index + 1}
                                                                  </td>
                                                                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                                      <a
                                                                          href={
                                                                              file.file_link
                                                                          }
                                                                          target="_blank"
                                                                          rel="noopener noreferrer"
                                                                          className="text-blue-500 font-semibold hover:underline capitalize"
                                                                      >
                                                                          {file.fileType.name}
                                                                      </a>
                                                                  </td>
                                                              </tr>
                                                          )
                                                      )}
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>

                {application && (
                    <div className="  my-5">
                        <AnswersAnswered application={application} />
                    </div>
                )}

                <div className="border border-primary ">
                    <div className="bg-primary flex flex-row justify-center p-2 text-lg  text-white ">
                        Evaluator decisions and comments
                    </div>
                    <table className="table-auto w-full">
                        <tbody>
                            <tr>
                                <td className="font-bold">Evaluator</td>
                                <td className="font-bold">Decision</td>
                                <td className="font-bold">Comment</td>
                            </tr>
                            <tr>
                                <td className="font-medium">Evaluator 1</td>
                                <td>
                                    {filteredUser?.evaluator_one_marks || '-'}
                                </td>
                                <td>
                                    {filteredUser?.evaluator_one_stepOne_comment ||
                                        '-'}
                                </td>
                            </tr>

                            <tr>
                                <td className="font-medium">Evaluator 2</td>
                                <td>
                                    {filteredUser?.evaluator_two_marks || '-'}
                                </td>
                                <td>
                                    {filteredUser?.evaluator_two_stepOne_comment ||
                                        '-'}
                                </td>
                            </tr>
                            <tr>
                                <td className="font-medium">Evaluator 3</td>
                                <td>
                                    {filteredUser?.evaluatorThree
                                        ? filteredUser?.evaluator_three_marks ||
                                          '-'
                                        : 'No evaluator 3'}
                                </td>
                                <td>
                                    {filteredUser?.evaluatorThree
                                        ? filteredUser?.evaluator_three_stepOne_comment ||
                                          '-'
                                        : 'No evaluator 3'}
                                </td>
                            </tr>
                            <tr>
                                <td className="font-medium">
                                    Academic manager
                                </td>
                                <td>
                                    {filteredUser?.academicManager
                                        ? filteredUser?.academic_manager_decision ||
                                          '-'
                                        : 'No Academic manager '}
                                </td>
                                <td>-</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="">
                    <p className="text-xl font-bold "> Final Decision </p>
                </div>
                <div className="flex flex-col justify-between space-x-2">
                    <div className="flex flexo-row justify-between space-x-3 mt-2 ">
                        <p className="mt-1 font-semibold text-medium ">
                            Application decision :
                        </p>
                        <select
                            name=""
                            id=""
                            className="form-select"
                            onChange={e => setMarks(e.target.value)}
                        >
                            <option value="">Select your Decisions</option>
                            <option value="pending">Pending</option>
                            <option value="reject">Reject</option>
                            <option value="approved">Approve</option>
                            <option value="accepted_scholarship">
                                Accepted Scholarship
                            </option>
                            <option value="accepted_self_funded">
                                Accepted Self Funded
                            </option>
                            <option value="accepted_virtual_participant">
                                Accepted Virtual Participant
                            </option>
                            <option value="accepted_local_participant">
                                Accepted Local Participant
                            </option>
                            <option value="shortlisted_for_interview">
                                Shortlisted for interview
                            </option>
                        </select>
                    </div>
                </div>
                <div className="flex  mt-4 flex-row justify-end">
                    <button
                        className="btn btn-primary "
                        onClick={handleMarkApplication}
                    >
                        Submit
                    </button>
                </div>
            </div>
            <ConfirmationModal
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                onConfirm={async () => {
                    const res: any = { status };

                    await handleEvaluete(rowId, res);
                    navigate(
                        '/account/modulator/evaluation?pageNumber=1&pageSize=10'
                    );
                    setConfirmationOpen(false);
                }}
            />
        </>
    );
}

export default ReviewUserStep3;

function AnswersAnswered({ application }: any) {
    return (
        <div>
            <div className=" border border-primary mt-3 ">
                <div className=" uppercase text-lg font-bold p-2  bg-primary flex flex-row justify-center  text-white ">
                    Application Details
                </div>
                <div className="p-3 ">
                    <h1 className="text-xl font-bold mb-4 text-primary">
                        {application?.title}
                    </h1>

                    <div className="mb-6 ">
                        <div
                            className=" dark:text-gray-400  "
                            dangerouslySetInnerHTML={{
                                __html: application?.description || '',
                            }}
                        />
                    </div>
                    {application?.topics &&
                        application?.topics.map((topic: any) => (
                            <div>
                                <p className=" text-lg font-bold  ">Topic</p>
                                <div>
                                    <h3 className="text-lg font-bold mb-2   file: mx-2  text-gray-800 dark:text-gray-400">
                                        Name:{' '}
                                        <span className="font-normal ">
                                            {topic?.name}
                                        </span>
                                    </h3>
                                    <p className="mx-2 font-bold   text-lg text-gray-800 dark:text-gray-400">
                                        Description:{' '}
                                        <span className=" font-normal">
                                            {topic?.description}
                                        </span>
                                    </p>
                                </div>
                                <div>
                                    <p className="my-2  text-lg font-bold  ">
                                        Questions
                                    </p>
                                    <div className="flex flex-col space-y-4">
                                        {topic?.questions?.map(
                                            (question: any, index: number) => (
                                                <div
                                                    key={question.id}
                                                    className="flex flex-col  items-start space-y-2"
                                                >
                                                    <div className="flex flex-row ">
                                                        <div className=" bg-gray-200 p-3 rounded-lg max-w-md">
                                                            <div>
                                                                <p className=" capitalize ">
                                                                    {' '}
                                                                    <span className="text-lg font-bold ">
                                                                        {index +
                                                                            1}
                                                                        .
                                                                    </span>{' '}
                                                                    {
                                                                        question.title
                                                                    }
                                                                </p>
                                                                <p className=" ml-4 ">
                                                                    {
                                                                        question.description
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" ml-4 ">
                                                        <div className=" font-bold ">
                                                            <p className="">
                                                                Answer
                                                            </p>
                                                        </div>

                                                        {question?.answers?.map(
                                                            (answer: any) => (
                                                                <div
                                                                    key={
                                                                        answer.id
                                                                    }
                                                                    className="flex  mt-1   flex-col items-end"
                                                                >
                                                                    <div className="bg-gray-100 p-3 rounded-lg">
                                                                        <p className="">
                                                                            {
                                                                                answer.answer
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}

                    <div className="mt-4 ">
                        {application?.suggestedTopics.length > 0 && (
                            <p className=" text-lg font-bold  ">
                                Suggested Topics
                            </p>
                        )}
                        {application?.suggestedTopics &&
                            application?.suggestedTopics.map(
                                (topic: any, index: any) => (
                                    <div>
                                        <div className="mt-2 ">
                                            <h3 className="text-lg  font-bold  mx-2  text-gray-800">
                                                {index + 1}. name:{' '}
                                                <span className=" ">
                                                    {topic?.title}
                                                </span>
                                            </h3>
                                            <p className="mx-2 font-bold  text-md    text-gray-600">
                                                Description{' '}
                                                <span className="font-normal">
                                                    {topic?.description}
                                                </span>
                                            </p>
                                        </div>
                                        <div>
                                            <div className="flex flex-col px-4">
                                                <div className="  text-lg font-bold ">
                                                    <p className="">
                                                        Skills Needed
                                                    </p>
                                                </div>

                                                <div className="flex flex-wrap mt-2">
                                                    {topic?.skills_needed?.map(
                                                        (skill: any) => (
                                                            <div
                                                                key={
                                                                    skill?.name
                                                                }
                                                                className="bg-primary text-white py-1 px-2 rounded-full mr-2 mb-2"
                                                            >
                                                                # {skill?.name}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                    </div>
                </div>
            </div>
        </div>
    );
}

const ConfirmationModal = ({ open, setOpen, onConfirm }: any) => {
    return (
        <Modal show={open} setShow={setOpen} title="Confirm Submission">
            <p>Are you sure you want to submit the decisions</p>
            <div className="flex gap-3  justify-end">
                <Button onClick={() => setOpen(false)} background="bg-gray-500">
                    Cancel
                </Button>
                <Button onClick={onConfirm}>Submit</Button>
            </div>
        </Modal>
    );
};
