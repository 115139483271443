import { toast } from 'react-hot-toast';
import { useFetchSocioEconomicByUser } from '@/core/hooks/social';
import { useAppSelector } from '@/store';
import IconArrowForward from '@/components/Icon/IconArrowForward';
import { useState, useEffect } from 'react';



const displayMappings: any = {
  parentsStatus: {
    1: 'Both parents alive',
    2: 'One parent deceased',
    4: 'Both parents deceased',
  },
  mainBreadwinner: {
    1: 'Two parents',
    2: 'One parent',
    3: 'Other relative/my sibling',
    4: 'Myself',
  },
  householdDependents: {
    1: '0',
    2: '1 - 2',
    3: '3 to 5',
    4: 'More than 5',
  },
  houseOwnership: {
    1: 'Family owned',
    2: 'Rented',
    3: 'Government subsidized house',
  },
  landOwnership: {
    1: 'Yes',
    2: 'No',
  },
  residenceType: {
    1: 'Urban',
    2: 'Peri-urban',
    3: 'Rural',
    4: 'Urban/Peri-urban informal settlement',
  },
  roofingMaterial: {
    1: 'Cement/concrete',
    2: 'Iron sheet/tin',
    4: 'Grass/other improvised materials',
  },
  householdIncome: {
    1: '2001 - above',
    2: '1501 - 2000 USD',
    3: '1001 - 1500 USD',
    4: '501 - 1000 USD',
    5: '0 - 500 USD',
  },
  governmentSupport: {
    0: 'No',
    2: 'Yes',
  },
  cookingEnergy: {
    1: 'Electricity',
    2: 'Liquefied Petroleum Gas (LPG)',
    3: 'Charcoal/paraffin',
    4: 'Firewood',
  },
  schoolDropoutReason: {
    0: 'Poor grades or sickness',
    1: 'Family migration',
    2: 'Conflict (tribal, resource-based)',
    3: 'Lack of fees/uniform/school supplies',
  },
};

export const SocioEconomicPage = ({ userId }: { userId?: string }) => {
  const { userAccount: userData }: any = useAppSelector(store => store.user);
  const { handleFetchByUser, loading, data } = useFetchSocioEconomicByUser();
  const [socioData, setSocioData] = useState<any>(null);

  const effectiveUserId = userId || userData?.id;

  useEffect(() => {
    if (effectiveUserId) {
      handleFetchByUser(effectiveUserId);
    }
  }, [effectiveUserId]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const userSocioData = data.find((item: any) => item.user?.id === effectiveUserId);
      if (userSocioData) {
        setSocioData(userSocioData);
      } else {
        toast.error('No socio-economic data found for this user.');
      }
    }
  }, [data, effectiveUserId]);

  if (loading) {
    return <p className="text-center text-lg text-gray-700">Loading socio-economic data...</p>;
  }

  if (!socioData) {
    return <p className="text-center text-lg text-gray-500">No socio-economic data available.</p>;
  }

  return (
    <div className="p-6 mx-auto">
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-2">
        <DisplayRow label="1. Status of parents" value={displayMappings.parentsStatus[socioData.parentsStatus]} />
        <DisplayRow label="2. Who is the main breadwinner for your household?" value={displayMappings.mainBreadwinner[socioData.mainBreadwinner]} />
        <DisplayRow label="3. How many people are dependent on your household income?" value={displayMappings.householdDependents[socioData.householdDependents]} />
        <DisplayRow label="4. Who owns the house where your family lives?" value={displayMappings.houseOwnership[socioData.houseOwnership]} />
        <DisplayRow label="5. Does your family own a piece of land?" value={displayMappings.landOwnership[socioData.landOwnership]} />
        <DisplayRow label="6. In which locality does your family live?" value={displayMappings.residenceType[socioData.residenceType]} />
        <DisplayRow label="7. What is the type of roofing material for the house you live in?" value={displayMappings.roofingMaterial[socioData.roofingMaterial]} />
        <DisplayRow label="8. Under which of the following categories does your household total monthly income fall?" value={displayMappings.householdIncome[socioData.householdIncome]} />
        <DisplayRow label="9. Does your household rely on any government support or social support?" value={displayMappings.governmentSupport[socioData.governmentSupport]} />
        <DisplayRow label="10. What is the main source of energy for cooking in your household?" value={displayMappings.cookingEnergy[socioData.cookingEnergy]} />
        <DisplayRow label="11. What was the reason for stopping school?" value={socioData.schoolDropoutReason !== null ? displayMappings.schoolDropoutReason[socioData.schoolDropoutReason] : 'N/A'} />
      </div>
    </div>
  );
};

  
  const DisplayRow = ({ label, value }: { label: string; value: string }) => (
    <div className="p-2 border-l-4 border-primary">
    <p className=" font-bold text-gray-900 mb-3">{label}</p>
    <p className="text-gray-700  font-medium  flex flex-row pl-4"><IconArrowForward/>{value}</p>
  </div>
  );
  