import {
    useGetMyPersonalInfo,
} from '@/core/hooks/personal_information';
import { AddressType } from '@/core/types/address';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { useEffect, useState } from 'react';
import 'tippy.js/dist/tippy.css';
import { capitalize } from '@mui/material';
import { GetAcademics } from '@/core/hooks/academics';
import { AcademicInfoType } from '@/core/types/academic';
import { GetExperiences } from '@/core/hooks/experience';
import { ExperienceType } from '@/core/types/experience';
import { GetLanguages } from '@/core/hooks/languages';
import { GetReferences } from '@/core/hooks/Reference';
import { ReferenceType } from '@/core/types/reference';
import { LanguageType } from '@/core/types/languages';
import { Getfiless } from '@/core/hooks/file'

function MyProfile() {
    const { handleGetMyPersonalInfo, loadingInformation, personalInfo } =
        useGetMyPersonalInfo();
    const { handleGetAccademics, LoadingAcademics, academics } = GetAcademics();
    const { handleGetExperiences, loadingExperiences, experiences } =
        GetExperiences();
    const { handleGetLanguages, loadingLanguages, languages } = GetLanguages();
    const { handleGetReferences, loadingReferences, references } =
        GetReferences();
    useEffect(() => {
        handleGetMyPersonalInfo();
        handleGetExperiences();
        handleGetLanguages();
        handleGetReferences();
        handleGetAccademics();
    }, []);
   
    const querry = location.search
    const { loadingfiless, filess, handleGetfiless } = Getfiless();
   
    useEffect(() => {
        handleGetfiless(querry);
    }, [querry]);
    return (
        <div className="pt-5 font-semibold flex flex-col gap-3">
            <div className=" border border-primary  ">
                <div className="bg-primary flex flex-row justify-center p-2 text-lg  text-white ">
                    PROFILE INFORMATION
                </div>
                <div className="w-full py-1 flex justify-center items-center   bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold">
                    IDENTIFICATION
                </div>
                <div className="py-4 flex justify-start items-start gap-3 flex-wrap">
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            First Name:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.firstName}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Middle Name:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.middleName || 'None'}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Last Name:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.lastName || '-'}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Phone Number:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.phoneNumber}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">Email:</p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.email}
                        </p>
                    </div>{' '}
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">Gender:</p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.gender === 'prefer_not_to_say'
                                ? 'Not Stated'
                                : personalInfo?.gender}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Date of Birth:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {formatDateToLongForm(personalInfo?.date_of_birth)}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Martial Status:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.maritalStatus}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Nationality:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.Nationality}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Citizenship:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.citizenship || '-'}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            FaceBook:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.facebook || '-'}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">Twitter:</p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.twitter}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            LinkedIn:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.linkedIn}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Has Passport :
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.hasPassport ? 'Yes' : 'No'}
                        </p>
                    </div>
                    {personalInfo?.hasPassport && (
                        <>
                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                <p className="font-bold dark:text-gray-300">
                                    Passport Number:
                                </p>
                                <p className="font-semibold text-gray-500">
                                     {personalInfo?.passportNumber || '-'}
                                </p>
                            </div>
                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                <p className="font-bold dark:text-gray-300">
                                    Passport Expiry Date:
                                </p>
                                <p className="font-semibold text-gray-500">
                                    {formatDateToLongForm(personalInfo?.passport_expired_data) || '-'}
                                </p>
                            </div>
                        </>
                    )}
                   
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Is refugee:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.refugee_status}
                        </p>
                    </div>
                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            Refugee Support Details:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.refugee_support_details  || '-'}
                        </p>
                    </div>

                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                        <p className="font-bold dark:text-gray-300">
                            {' '}
                            Have disablility:
                        </p>
                        <p className="font-semibold text-gray-500">
                            {personalInfo?.disability_status}
                        </p>
                    </div>
                    {personalInfo?.disability_status && (
                        <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                            <p className="font-bold dark:text-gray-300">
                                Disability Accommodations:
                            </p>
                            <p className="font-semibold text-gray-500">
                                {personalInfo?.disability_accommodations || '-'}
                            </p>
                        </div>)}
                </div>
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold">
                    ADDRESS INFORMATION
                </div>

                <div className="flex justify-start items-start gap-3 flex-wrap">
                    <div className=" paneltable-responsive  w-full m-5">
                        <table className=" border  rounded  ">
                            <thead className=" ">
                                <tr>
                                    <th className="font-bold">Address Type</th>
                                    <th className="font-bold">City</th>
                                    <th className="font-bold">street</th>
                                    <th className="font-bold">Date Added</th>
                                </tr>
                            </thead>
                            <tbody>
                                {personalInfo?.address?.map(
                                    (data: AddressType) => {
                                        return (
                                            <tr key={data.id}>
                                                <td>
                                                    <div className="whitespace-nowrap">
                                                        {capitalize(data.type)}
                                                    </div>
                                                </td>
                                                <td>{capitalize(data.city)}</td>
                                                <td>{data.street || '-'}</td>
                                                <td>
                                                    {formatDateToLongForm(
                                                        data.created_at
                                                    ) || '-'}{' '}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                    Academic Information
                </div>

                <div className="flex justify-start items-start gap-3 flex-wrap">
                    <div className="table-responsive  w-full m-5">
                        <table className="border rounded   ">
                            <thead>
                                <tr>
                                    <th className="font-bold">Institution</th>
                                    <th className="font-bold">Country</th>

                                    <th className="font-bold whitespace-nowrap">
                                        Graduation Date
                                    </th>
                                    <th className="font-bold whitespace-nowrap ">
                                        Level of Study
                                    </th>
                                    <th className="font-bold">Degree</th>
                                    <th className="font-bold">Major</th>

                                    <th className="font-bold whitespace-nowrap">
                                        Language of Instructions
                                    </th>
                                    <th className="font-bold whitespace-nowrap">
                                        Highest Qualification
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {academics?.list?.map(
                                    (data: AcademicInfoType) => {
                                        return (
                                            <tr key={data.id}>
                                                <td>{data.institution}</td>
                                                <td>{data.country}</td>

                                                <td>
                                                    {formatDateToLongForm(
                                                        data.graduationDate
                                                    ) || '-'}
                                                </td>
                                                <td>{data.levelOfStudy}</td>
                                                <td>{data.degree}</td>
                                                <td>{data.major}</td>

                                                <td>
                                                    {data.language_of_instructions ||
                                                        '-'}
                                                </td>
                                                <td>
                                                    {data.highest_qualification
                                                        ? 'Yes'
                                                        : 'No'}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                    Experience Information
                </div>

                <div className="flex justify-start items-start gap-3 flex-wrap">
                    <div className="table-responsive  w-full m-5">
                        <table className="border rounded">
                            <thead>
                                <tr>
                                    <th className="font-bold whitespace-nowrap">
                                        Employer Name
                                    </th>
                                    <th className="font-bold">Country</th>

                                    <th className="font-bold whitespace-nowrap">
                                        Start Date
                                    </th>
                                    <th className="font-bold whitespace-nowrap">
                                        End Date
                                    </th>
                                    <th className="font-bold">Position</th>
                                    <th className="font-bold whitespace-nowrap">
                                        Roles and Responsibilities
                                    </th>
                                    <th className="font-bold">Type</th>
                                    <th className="font-bold whitespace-nowrap">
                                        Employer Sector
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {experiences?.list?.map(
                                    (data: ExperienceType) => {
                                        return (
                                            <tr key={data.id}>
                                                <td>{data.employer_name}</td>
                                                <td>{data.country}</td>

                                                <td>
                                                    {formatDateToLongForm(
                                                        data.start_date
                                                    ) || '-'}
                                                </td>
                                                <td>
                                                    {formatDateToLongForm(
                                                        data.end_date
                                                    ) || '-'}
                                                </td>
                                                <td>{data.position}</td>
                                                <td>
                                                    {data.roles_and_responsibilities ||
                                                        '-'}
                                                </td>
                                                <td>{data.type}</td>
                                                <td>
                                                    {data.employer_sector ||
                                                        '-'}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                    Languages
                </div>

                <div className="flex justify-start items-start gap-3 flex-wrap">
                    <div className="table-responsive  w-full m-5">
                        <table className="border rounded">
                            <thead>
                                <tr>
                                    <th className="font-bold">Language</th>
                                    <th className="font-bold">Verbal Level</th>
                                    <th className="font-bold">Written Level</th>
                                </tr>
                            </thead>
                            <tbody>
                                {languages?.list?.map((data: LanguageType) => {
                                    return (
                                        <tr key={data.id}>
                                            <td>{data.name}</td>
                                            <td>{data.verbal_level}</td>
                                            <td>{data.written_level}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                    Social Economic Information
                </div>

                <div className="flex justify-start items-start gap-3 flex-wrap">
                   <SocioEconomicPage/>
                </div>
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                    reference information
                </div>

                <div className="flex justify-start items-start gap-3 flex-wrap">
                    <div className="table-responsive  w-full m-5">
                        <table className="border rounded">
                            <thead>
                                <tr>
                                    <th className="font-bold whitespace-nowrap">
                                        First Name
                                    </th>
                                    <th className="font-bold whitespace-nowrap">
                                        Last Name
                                    </th>
                                    <th className="font-bold">Company</th>
                                    <th className="font-bold">Position</th>
                                    <th className="font-bold whitespace-nowrap">
                                        Relationship
                                    </th>
                                    <th className="font-bold">Email</th>
                                    <th className="font-bold whitespace-nowrap ">
                                        Phone Number
                                    </th>
                                    <th className="font-bold">Country</th>
                                </tr>
                            </thead>
                            <tbody>
                                {references?.list?.map(
                                    (data: ReferenceType) => {
                                        return (
                                            <tr key={data.id}>
                                                <td>{data.firstName}</td>
                                                <td>{data.lastName}</td>
                                                <td>{data.company}</td>
                                                <td>{data.position}</td>
                                                <td>{data.relationship}</td>
                                                <td>{data.email}</td>
                                                <td>{data.phoneNumber}</td>
                                                <td>{data.country}</td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                <div className="panel mx-w-lg">
         
                <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                    Attachment 
                </div>
                {filess && filess?.list?.map((file:any, index:number) => (
                       <a href={file.file_link}    
                       target="_blank"
                       rel="noopener noreferrer" className="text-blue-500 font-semibold hover:underline capitalize">
                  <p key={file.id} className="py-2 ">
                  
                  {index+1}.  <span className="text-gray-600">{file.fileType.name}</span>
                      </p>
                      </a>
                  ))}
              
          </div>
                </div>
            </div>
        </div>
    );
}

export default MyProfile;



import { toast } from 'react-hot-toast';
import { useFetchSocioEconomicByUser } from '@/core/hooks/social';
import { useAppSelector } from '@/store';
import IconArrowForward from '@/components/Icon/IconArrowForward';



const displayMappings: any = {
  parentsStatus: {
    1: 'Both parents alive',
    2: 'One parent deceased',
    4: 'Both parents deceased',
  },
  mainBreadwinner: {
    1: 'Two parents',
    2: 'One parent',
    3: 'Other relative/my sibling',
    4: 'Myself',
  },
  householdDependents: {
    1: '0',
    2: '1 - 2',
    3: '3 to 5',
    4: 'More than 5',
  },
  houseOwnership: {
    1: 'Family owned',
    2: 'Rented',
    3: 'Government subsidized house',
  },
  landOwnership: {
    1: 'Yes',
    2: 'No',
  },
  residenceType: {
    1: 'Urban',
    2: 'Peri-urban',
    3: 'Rural',
    4: 'Urban/Peri-urban informal settlement',
  },
  roofingMaterial: {
    1: 'Cement/concrete',
    2: 'Iron sheet/tin',
    4: 'Grass/other improvised materials',
  },
  householdIncome: {
    1: '2001 - above',
    2: '1501 - 2000 USD',
    3: '1001 - 1500 USD',
    4: '501 - 1000 USD',
    5: '0 - 500 USD',
  },
  governmentSupport: {
    0: 'No',
    2: 'Yes',
  },
  cookingEnergy: {
    1: 'Electricity',
    2: 'Liquefied Petroleum Gas (LPG)',
    3: 'Charcoal/paraffin',
    4: 'Firewood',
  },
  schoolDropoutReason: {
    0: 'Poor grades or sickness',
    1: 'Family migration',
    2: 'Conflict (tribal, resource-based)',
    3: 'Lack of fees/uniform/school supplies',
  },
};

const SocioEconomicPage = ({ userId }: { userId?: string }) => {
  const { userAccount: userData }: any = useAppSelector(store => store.user);
  const { handleFetchByUser, loading, data } = useFetchSocioEconomicByUser();
  const [socioData, setSocioData] = useState<any>(null);

  const effectiveUserId = userId || userData?.id;

  useEffect(() => {
    if (effectiveUserId) {
      handleFetchByUser(effectiveUserId);
    }
  }, [effectiveUserId]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      const userSocioData = data.find((item: any) => item.user?.id === effectiveUserId);
      if (userSocioData) {
        setSocioData(userSocioData);
      } else {
        toast.error('No socio-economic data found for this user.');
      }
    }
  }, [data, effectiveUserId]);

  if (loading) {
    return <p className="text-center text-lg text-gray-700">Loading socio-economic data...</p>;
  }

  if (!socioData) {
    return <p className="text-center text-lg text-gray-500">No socio-economic data available.</p>;
  }

  return (
    <div className="p-6 mx-auto">
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-2">
        <DisplayRow label="1. Status of parents" value={displayMappings.parentsStatus[socioData.parentsStatus]} />
        <DisplayRow label="2. Who is the main breadwinner for your household?" value={displayMappings.mainBreadwinner[socioData.mainBreadwinner]} />
        <DisplayRow label="3. How many people are dependent on your household income?" value={displayMappings.householdDependents[socioData.householdDependents]} />
        <DisplayRow label="4. Who owns the house where your family lives?" value={displayMappings.houseOwnership[socioData.houseOwnership]} />
        <DisplayRow label="5. Does your family own a piece of land?" value={displayMappings.landOwnership[socioData.landOwnership]} />
        <DisplayRow label="6. In which locality does your family live?" value={displayMappings.residenceType[socioData.residenceType]} />
        <DisplayRow label="7. What is the type of roofing material for the house you live in?" value={displayMappings.roofingMaterial[socioData.roofingMaterial]} />
        <DisplayRow label="8. Under which of the following categories does your household total monthly income fall?" value={displayMappings.householdIncome[socioData.householdIncome]} />
        <DisplayRow label="9. Does your household rely on any government support or social support?" value={displayMappings.governmentSupport[socioData.governmentSupport]} />
        <DisplayRow label="10. What is the main source of energy for cooking in your household?" value={displayMappings.cookingEnergy[socioData.cookingEnergy]} />
        <DisplayRow label="11. What was the reason for stopping school?" value={socioData.schoolDropoutReason !== null ? displayMappings.schoolDropoutReason[socioData.schoolDropoutReason] : 'N/A'} />
      </div>
    </div>
  );
};

  
  const DisplayRow = ({ label, value }: { label: string; value: string }) => (
    <div className="p-2 border-l-4 border-primary">
    <p className=" font-bold text-gray-900 mb-3">{label}</p>
    <p className="text-gray-700  font-medium  flex flex-row pl-4"><IconArrowForward/>{value}</p>
  </div>
  );
  